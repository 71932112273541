import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { applicationsSingle } from "../../../../../store/applications/applications-single/applicationsSingleSlice";
import ApplicationItemModal from "../applications-item-modal/applications-item-modal";
import ApplicationSliderImage from "../application-slider-image/applications-slider-image";
import { colorStatus } from "../../../../../utils/status-color";
import ApplicationsEditButton from "./applications-item-button/applications-edit-button/applications-edit-button";
import ApplicationAcceptDenyButton from "./applications-item-button/application-accept-deny-button/application-accept-deny-button";
import ApplicationDeleteButton from "./applications-item-button/application-delete-button/application-delete-button";
import ApplicationIssueButton from "./applications-item-button/application-issue-button/application-issue-button";
import ApplicationGenereteAct from "./applications-item-button/applications-generete-act/applications-generete-act";
import {editParkingService} from "../../../../../store/parkings/parking-edit-services-slice/parkingEditServicesSlice";
import ApplicationItemServicesModal from "../application-item-services-modal/application-item-services-modal";
import {
  applicationServicesAddToOrder,
  applicationServicesGetById
} from "../../../../../store/applications/application-service-order/applicationServiceOrderSlice";

function ApplicationItem({ item, setFavorite }) {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [currentModalInfo, setCurrentModalInfo] = useState(null);




  const openModal = (id) => {
    setIsModalOpen(true);
    dispatch(applicationsSingle(id)).then((resp)=>{
      setCurrentModalInfo(resp.payload)

    });
    let currentApplicationId = id;
      dispatch(applicationServicesGetById({currentApplicationId})).then((response)=>{
      })
  };

  const openServicesModal = () => {
    setIsServiceModalOpen(true)
  }
  const closeServicesModal = () => {
    setIsServiceModalOpen(false)
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentModalInfo(null)

  };


  // Modal data
  const { single } = useSelector((state) => state.applicationSingle);
  const { user } = useSelector((state) => state.auth);


  return (
    <>
      <div className="col-md-3">
        <div className="car-col__item">
          <ApplicationSliderImage item={item} setFavorite={setFavorite} />

          <div className="car-col__info">
            <div className="car-show-modal mk">
              <h3 className="car-title" onClick={() => openModal(item.id)}>
                <span className="car-show-info mk">{item.car_title}</span>
                <span className="show-modal-chat">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 1.5C0.5 0.671573 1.17157 0 2 0H14C14.8284 0 15.5 0.671572 15.5 1.5V9.75C15.5 10.5784 14.8284 11.25 14 11.25H10.5607L8.53033 13.2803C8.23744 13.5732 7.76256 13.5732 7.46967 13.2803L5.43934 11.25H2C1.17157 11.25 0.5 10.5784 0.5 9.75V1.5ZM14 1.5H2V9.75H5.75C5.94891 9.75 6.13968 9.82902 6.28033 9.96967L8 11.6893L9.71967 9.96967C9.86032 9.82902 10.0511 9.75 10.25 9.75H14V1.5Z"
                      fill="#536E9B"
                    ></path>
                    <path
                      d="M9.125 5.625C9.125 6.24632 8.62132 6.75 8 6.75C7.37868 6.75 6.875 6.24632 6.875 5.625C6.875 5.00368 7.37868 4.5 8 4.5C8.62132 4.5 9.125 5.00368 9.125 5.625Z"
                      fill="#536E9B"
                    ></path>
                    <path
                      d="M12.125 5.625C12.125 6.24632 11.6213 6.75 11 6.75C10.3787 6.75 9.875 6.24632 9.875 5.625C9.875 5.00368 10.3787 4.5 11 4.5C11.6213 4.5 12.125 5.00368 12.125 5.625Z"
                      fill="#536E9B"
                    ></path>
                    <path
                      d="M6.125 5.625C6.125 6.24632 5.62132 6.75 5 6.75C4.37868 6.75 3.875 6.24632 3.875 5.625C3.875 5.00368 4.37868 4.5 5 4.5C5.62132 4.5 6.125 5.00368 6.125 5.625Z"
                      fill="#536E9B"
                    ></path>
                  </svg>
                </span>
                <span className="d-none">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 1.5C0.5 0.671573 1.17157 0 2 0H14C14.8284 0 15.5 0.671572 15.5 1.5V9.75C15.5 10.5784 14.8284 11.25 14 11.25H10.5607L8.53033 13.2803C8.23744 13.5732 7.76256 13.5732 7.46967 13.2803L5.43934 11.25H2C1.17157 11.25 0.5 10.5784 0.5 9.75V1.5ZM14 1.5H2V9.75H5.75C5.94891 9.75 6.13968 9.82902 6.28033 9.96967L8 11.6893L9.71967 9.96967C9.86032 9.82902 10.0511 9.75 10.25 9.75H14V1.5Z"
                      fill="#536E9B"
                    />
                    <path
                      d="M9.125 5.625C9.125 6.24632 8.62132 6.75 8 6.75C7.37868 6.75 6.875 6.24632 6.875 5.625C6.875 5.00368 7.37868 4.5 8 4.5C8.62132 4.5 9.125 5.00368 9.125 5.625Z"
                      fill="#536E9B"
                    />
                    <path
                      d="M12.125 5.625C12.125 6.24632 11.6213 6.75 11 6.75C10.3787 6.75 9.875 6.24632 9.875 5.625C9.875 5.00368 10.3787 4.5 11 4.5C11.6213 4.5 12.125 5.00368 12.125 5.625Z"
                      fill="#536E9B"
                    />
                    <path
                      d="M6.125 5.625C6.125 6.24632 5.62132 6.75 5 6.75C4.37868 6.75 3.875 6.24632 3.875 5.625C3.875 5.00368 4.37868 4.5 5 4.5C5.62132 4.5 6.125 5.00368 6.125 5.625Z"
                      fill="#536E9B"
                    />
                  </svg>
                  <span className="car__cunt">12</span>
                </span>
              </h3>

              <span className="car__subtitle">ID {item.id}</span>
              <span className="car__subtitle">{item.parking?.title}</span>
            </div>

            {/* VIN AND LICENZE PLATE */}
            <div className="car-col__info-item">
              <div>
                <span>VIN</span>
                <div> {item.vin}</div>
              </div>
              <div>
                <span>Гос. номер</span>
                <div>{item.license_plate}</div>
              </div>
            </div>
            {/*  */}

            <div className="car-col__info-item">
              {/* Дата постановки */}
              <div
                className={`${
                  item.status_id === 7 ? "arrived-date-class" : ""
                }`}
              >
                <span>Дата постановки</span>
                <div>
                  {item.status_id === 7
                    ? item.arriving_date
                    : item.formated_arrived_at === null
                    ? "Нет"
                    : item.formated_arrived_at}
                </div>
              </div>
              {/*  */}

              {/* Дата выдачи */}
              <div className={`${item.issuance ? "issue-date-class" : ""}`}>
                <span>Дата выдачи</span>
                <div>
                  {item.dateTimeIssuance ? item.dateTimeIssuance : "Нет"}
                </div>
              </div>
              {/*  */}
            </div>

            <div className="car-col__info-item">
              <div className="car-status-wrap">
                {item.issuance ? (
                  <span className="car-status status-new">На выдачу</span>
                ) : (
                  ""
                )}
                <span className={`car-status  ${colorStatus(item.status.id)}`}>
                  {item.status.name} {item.returned ? "Повтор" : ""}
                </span>
              </div>

              <span> {item?.partner?.name}</span>
              <span> {item.external_id}</span>

              <div
                className={isActive ? "car-dd active" : "car-dd "}
                onClick={handleClick}
              >
                <div
                  className={isActive ? "car-close-dd active" : "car-close-dd"}
                ></div>
                <div className="car-dd-body">
                  {/* Принять отклонить и редактирование разбивка по ролям */}
                  {user.role !== 'Partner' ?
                  <ApplicationAcceptDenyButton item={item} role={user.role} /> : ""}
                  {/*  */}

                  {/* Скачать акт */}
                  <ApplicationGenereteAct item={item} role={user.role} />
                  {/*  */}

                  {/* Отображение кнопки редактирование  */}
                  <ApplicationsEditButton item={item} role={user.role} />
                  {/*  */}

                  {/*  Удалить */}
                  <ApplicationDeleteButton item={item} role={user.role} />
                  {/*  */}

                  {/* Выдать */}
                  <ApplicationIssueButton item={item} />
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && currentModalInfo ?
      <ApplicationItemModal
        single={currentModalInfo}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        setFavorite={setFavorite}
        openServicesModal={openServicesModal}
        isServiceModalOpen={isServiceModalOpen}
        closeServicesModal={closeServicesModal}
      /> :''}
    </>
  );
}

export default ApplicationItem;
