import React, {useEffect, useState} from 'react';
import {db} from "../../../../../../firebase";
import { ref, onValue } from 'firebase/database';
import {useDispatch, useSelector} from "react-redux";
import {applicationServicesGetById} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";

function ApplicationServiceList(
    {
        openCurrentOrders,
        formatDate,
        getStatusColor,
        handleSelect,
    }) {
    const [filteredServices, setFilteredServices] = useState([])
    const [activeTab, setActiveTab] = useState('all')
    const [ordersFromFireBase, setOrdersFromFireBase] = useState({});
    const [error, setError] = useState(null);
    const { single } = useSelector((state) => state.applicationSingle);
    const services  = useSelector((state) => state.applicationService.services.orders);
    const dispatch = useDispatch();

    // firebase
    useEffect(() => {
        const ordersRef = ref(db, `myparkingprod/orders-in-applications/${single.application?.id}`);
        const unsubscribe = onValue(ordersRef, (snapshot) => {
            if (!snapshot.val()){
                return
            }
            else {
                const newOrder = snapshot.val().order;
                setOrdersFromFireBase(snapshot.val());
                setError(null);
                setFilteredServices((prevOrders) => {
                    const orderExists = prevOrders.some(order => order?.id === newOrder?.id);

                    if (orderExists) {
                        return prevOrders.map(order =>
                            order?.id === newOrder?.id ? { ...order, ...newOrder } : order
                        );
                    } else {
                        // Add the new order
                        return [...prevOrders, newOrder];
                    }
                });
            }
        }, (error) => {
            setError(error.message);
        });
        return () => {
            unsubscribe();
        };
    }, []);
    const servicesFilter = (filterBy) => {
        switch (filterBy){
            case 'all':
                setActiveTab(filterBy)
                setFilteredServices(services)
                break;
            case 'toPartner':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'toPartner' || item.status_code === 'toManager'))
                break;
            case 'completed':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'completed' ))
                break;
            case 'in-progress':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'in-progress' || item.status_code === 'partly-completed' ))
                break;
            case 'cancelled':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'cancelled'))
                break;
            default:
                setFilteredServices(services)
        }
    }
    useEffect(() => {
        const currentApplicationId = single.application.id
        dispatch(applicationServicesGetById({currentApplicationId})).then((response)=>{
        })
    }, [])
    useEffect(() => {
        setFilteredServices(services)
    }, [services])

    return (
        <div className={'order__list row d-flex'}>
            <div className={'order__list-filters row mb-4 cursor-pointer'} >
                <div
                    className={`custom-w-20 ${activeTab === 'all' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('all')}}
                >
                    <span >Все({services.length}) </span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'toPartner' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('toPartner')}}
                >
                    <span>Согласование</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'in-progress' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('in-progress')}}
                >
                    <span>В работе</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'completed' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('completed')}}
                >
                    <span>Готово </span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'cancelled' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('cancelled')}}
                >
                    <span>Отменено</span>
                </div>
            </div>
            {filteredServices && filteredServices[0] !==0 && filteredServices?.length !== 0 ? filteredServices?.slice().reverse().map((item, index) => {
                return (
                    <a href="#" key={item?.id} className={'mb-3 col-md-4'} onClick={(e) => {
                        openCurrentOrders(item.id, item.application_id)
                        handleSelect('tabCurrentServices')
                    }}>
                        <span
                            style={{fontSize: '14px'}}
                        >
                            {`Заказ № ${item?.id}`}
                        </span>
                        <span
                            style={{fontSize: '12px'}}
                        >
                            {` от ${formatDate(item.created_at)}`}
                        </span>
                        <br/>
                        <span
                            className={'status_info_owner'}
                            style={{
                            fontSize: '11px',
                            color: getStatusColor(item.status_code),
                            textAlign: 'left',
                            }}
                        >
                            {item.status_name}
                            {item.status_code === 'toPartner'?
                                <p className={'small_text'}>(у Заказчика)</p>
                                : ''
                            }
                            {item.status_code === 'toManager'?
                                <p className={'small_text'}>(у Менеджера)</p>
                                : ''
                            }
                        </span>
                        {item.status_code === 'in-progress' || item.status_code === 'partly-completed' ?
                            <div className={'order-bar__info'}>
                                <div className="progress">
                                    <div
                                        className="progress-bar bg-success"
                                        role="progressbar"
                                        style={{width: "10%"}}
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>

                                </div>
                                <span className={'order__completed-count'}>6/12</span>
                            </div>
                            : ''
                        }

                    </a>
                )
                })
                :
                    <div className={'col-md-12 full-size'}>
                        <b>У вас нет активных заказов</b>
                        <button
                            onClick={()=>{handleSelect('tabServices')}}
                        >
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 4.5C13.0523 4.5 13.5 4.94772 13.5 5.5V11.5H19.5C20.0523 11.5 20.5 11.9477 20.5 12.5C20.5 13.0523 20.0523 13.5 19.5 13.5H13.5V19.5C13.5 20.0523 13.0523 20.5 12.5 20.5C11.9477 20.5 11.5 20.0523 11.5 19.5V13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5C4.5 11.9477 4.94772 11.5 5.5 11.5H11.5V5.5C11.5 4.94772 11.9477 4.5 12.5 4.5Z" fill="white"/>
                            </svg>
                            Заказать услуги
                        </button>
                    </div>
            }
        </div>


    )
}

export default ApplicationServiceList;