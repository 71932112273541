import {useEffect, useState} from "react";
import Preloader from "../../../../../preloader/preloader";
import {useDispatch, useSelector} from "react-redux";
import {currentApplicationServiceById} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";

function ApplicationCurrentService(
    {
        service,
        handleDeleteService,
        setApprovedPrice,
        currentApplicationId,
        orderID
    }) {
    const dispatch = useDispatch();
    const currentServices  = useSelector((state) => state);
    const statusMap = {
        toApprove: { name: 'Необходимо согласовать', color: '#F2994A' },
        toFill: { name: 'Необходимо заполнить', color: '#EB5757' },
        ready: { name: 'Готово к работе', color: '#27AE60' },
        deleted: { name: 'Удалена', color: '#EB5757' }
    };
    const [pricesValue, setPricesValue] = useState({
        price_for_us: '0',
        approved_price: '0',
        parking_has_service_id: 0
    })
    const [currentStatus, setCurrentStatus] = useState(statusMap.toApprove)


    const statusHandler = () =>{
        console.log(parseInt(service.price_for_us),  parseInt(service.approved_price))

        if(parseInt(service.price_for_us) === 0 || parseInt(service.approved_price) === 0){
            setCurrentStatus(statusMap.toFill)
        }
        if (parseInt(service.price_for_us) !== 0 && parseInt(service.approved_price) !== 0){
            setCurrentStatus(statusMap.ready)
        }
    }



    useEffect(() => {
        setPricesValue({
            price_for_us: service.price_for_us,
            approved_price: service.approved_price,
            parking_has_service_id: service.parking_has_service?.id,
        })
    }, [service])
    useEffect(()=>{
        dispatch(currentApplicationServiceById({currentApplicationId, orderID})).then((response)=>{
            console.log(response)
        })
        statusHandler()
    },[])

    const handleChange = (e) => {
        const {name, value} = e.target ? e.target : e;
        setPricesValue({...pricesValue, [name]: value});
    };
    const changeDefaultPrice = (e, id) => {
        handleChange(e)
        if (e.target.name === 'approved_price') {
            setApprovedPrice(prevState => {
                return {
                    orderServices: prevState.orderServices.map(service => {
                        if (service.id === id) {
                            return {
                                ...service,
                                price: e.target.value,
                            };
                        }
                        return service;
                    })
                };
            });
            setCurrentStatus(parseInt(e.target.value) === 0 || parseInt(pricesValue.price_for_us) === 0  ? statusMap.toFill : statusMap.toApprove)
        }
        if (e.target.name === 'price_for_us') {
            setApprovedPrice(prevState => {
                return {
                    orderServices: prevState.orderServices.map(service => {
                        if (service.id === id) {
                            return {
                                ...service,
                                price_for_us: e.target.value
                            };
                        }
                        return service;
                    })
                };
            });
            setCurrentStatus(parseInt(e.target.value) === 0 || parseInt(pricesValue.approved_price) === 0 ? statusMap.toFill : statusMap.toApprove)
        }
    };
    return (
        <>
            {service ?
                <tr>
                <td className={'delete_service'}>
                    <span className="">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.52827 1.02729C0.788619 0.766944 1.21073 0.766944 1.47108 1.02729L4.99967 4.55589L8.52827 1.02729C8.78862 0.766944 9.21073 0.766944 9.47108 1.02729C9.73143 1.28764 9.73143 1.70975 9.47108 1.9701L5.94248 5.4987L9.47108 9.02729C9.73143 9.28764 9.73143 9.70975 9.47108 9.9701C9.21073 10.2305 8.78862 10.2305 8.52827 9.9701L4.99967 6.44151L1.47108 9.9701C1.21073 10.2305 0.788619 10.2305 0.52827 9.9701C0.26792 9.70975 0.26792 9.28764 0.52827 9.02729L4.05687 5.4987L0.52827 1.9701C0.26792 1.70975 0.26792 1.28764 0.52827 1.02729Z"
                                fill="#EB5757"/>
                        </svg>
                    </span>
                </td>
                <td
                    style={{maxWidth: '200px'}}
                >
                    <div className="table-service-title">{service.parking_has_service.parking_service.name}</div>
                </td>

                <td className="text-center"
                    style={{maxWidth: '170px'}}
                >
                    {service.status === 'На согласовании' ?
                        <input
                            id="text"
                            type="text"
                            className="form-control "
                            name="approved_price"
                            autoComplete="text"
                            onChange={(e) => {
                                changeDefaultPrice(e, service.id)
                            }}
                            value={pricesValue.approved_price}
                        /> :
                        <span
                            className="form-control "
                            style={{fontSize: '1rem'}}
                        >
                            {pricesValue.approved_price}
                        </span>
                    }
                </td>
                <td className="text-center"
                    style={{maxWidth: '170px'}}
                >
                    {service.status === 'На согласовании' ?
                        <input
                            id="text"
                            type="text"
                            className="form-control "
                            name="price_for_us"
                            autoComplete="text"
                            onChange={(e) => {
                                changeDefaultPrice(e, service.id)
                            }}
                            value={pricesValue.price_for_us === null ? 0 : pricesValue.price_for_us}
                        /> :
                        <span
                            className="form-control "
                            style={{fontSize: '1rem'}}

                        >
                            {pricesValue.price_for_us}
                        </span>
                    }


                </td>
                <td className="text-right">
                    <label className="switch-radio-wrap d-flex flex-row-reverse">
                        <span
                            style={{color: currentStatus.color}}
                            className="condition"
                        >
                            {
                                currentStatus.name
                            }
                        </span>
                    </label>
                </td>
                {/*<td>*/}
                {/*{service.status === 'На согласовании' ?*/}
                {/*    <label>*/}
                {/*         <span*/}
                {/*             style={{cursor: 'pointer'}}*/}
                {/*             onClick={() => {*/}
                {/*                 handleDeleteService(service.id)*/}
                {/*             }}*/}
                {/*         >*/}
                {/*             <svg width="14" height="14" viewBox="0 0 14 14" fill="none"*/}
                {/*                  xmlns="http://www.w3.org/2000/svg">*/}
                {/*                 <g opacity="0.6">*/}
                {/*                     <path*/}
                {/*                         d="M4.08335 2.33329C4.08335 1.68896 4.60569 1.16663 5.25002 1.16663H8.75002C9.39435 1.16663 9.91669 1.68896 9.91669 2.33329V3.49996H11.0773C11.0809 3.49993 11.0846 3.49993 11.0882 3.49996H12.25C12.5722 3.49996 12.8334 3.76113 12.8334 4.08329C12.8334 4.40546 12.5722 4.66663 12.25 4.66663H11.6265L11.1206 11.7497C11.077 12.3603 10.5689 12.8333 9.95687 12.8333H4.04317C3.43109 12.8333 2.92308 12.3603 2.87947 11.7497L2.37353 4.66663H1.75002C1.42785 4.66663 1.16669 4.40546 1.16669 4.08329C1.16669 3.76113 1.42785 3.49996 1.75002 3.49996H2.91187C2.91549 3.49993 2.9191 3.49993 2.92271 3.49996H4.08335V2.33329ZM5.25002 3.49996H8.75002V2.33329H5.25002V3.49996ZM3.54317 4.66663L4.04317 11.6666H9.95687L10.4569 4.66663H3.54317ZM5.83335 5.83329C6.15552 5.83329 6.41669 6.09446 6.41669 6.41663V9.91663C6.41669 10.2388 6.15552 10.5 5.83335 10.5C5.51119 10.5 5.25002 10.2388 5.25002 9.91663V6.41663C5.25002 6.09446 5.51119 5.83329 5.83335 5.83329ZM8.16669 5.83329C8.48885 5.83329 8.75002 6.09446 8.75002 6.41663V9.91663C8.75002 10.2388 8.48885 10.5 8.16669 10.5C7.84452 10.5 7.58335 10.2388 7.58335 9.91663V6.41663C7.58335 6.09446 7.84452 5.83329 8.16669 5.83329Z"*/}
                {/*                         fill="#EB5757"/>*/}
                {/*                 </g>*/}
                {/*             </svg>*/}
                {/*         </span>*/}
                {/*    </label>*/}
                {/*    : ''}*/}
                {/*</td>*/}
            </tr> : <Preloader/>


            }
        </>
    );
}

export default ApplicationCurrentService;