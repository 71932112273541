import React from 'react';
import ApplicationLogsItem from "./application-logs-item/application-logs-item";

function ApplicationLogs({logs}) {
    return (
        <div>
            <div className="log-filters">
                <div className="log-filters__item active">
                    <span>Все логи</span>
                </div>
                <div className="log-filters__item">
                    <span>Заказы</span>
                </div>
                <div className="log-filters__item">
                    <span>Статусы</span>
                </div>

            </div>
            <table className="table logs-table">
                <thead>
                <tr>
                    {/*<th>ID</th>*/}
                    <th>Дата</th>
                    <th>Действие</th>
                </tr>
                </thead>
                <tbody>
                {logs.map((item) => {
                    return (
                        <ApplicationLogsItem
                            id={item.id}
                            key={item.id}
                            date={item.date}
                            action={item.action}
                        />
                    )
                })}
                </tbody>
            </table>

        </div>
    );
}

export default ApplicationLogs;