import React, {useEffect, useState} from 'react';

import {limitToLast, onValue, ref} from "firebase/database";
import {db} from "../../../../../../firebase";
import {useSelector} from "react-redux";

function AplicationServices(
    {
        single,
        getApplicationSelectedServices,
        getSelectedServicesForFooterInfo,
    }) {
    const {user} = useSelector((state) => state.auth);

    const [selectedServices, setSelectedServices] = useState([]);
    const [parkingId, setParkingId] = useState(null);
    const [categories, setCategories] = useState([])
    const [groupedData, setGroupedData] = useState([]);
    const [filterId, setFilterId] = useState(null)


    // Function to handle checkbox click
    const collectDataAboutSelectedServices = (e, parkingId, serviceId, cost, cost_for_us, name) => {
        setParkingId(parkingId);
        setSelectedServices((prevServices) => {
            if (e.target.checked) {
                return [
                    ...prevServices,
                    {
                        parking_has_service_id: serviceId,
                        price: cost,
                        price_for_us: cost_for_us,
                        name: name,
                    },
                ];
            } else {
                return prevServices.filter(
                    (service) => service.parking_has_service_id !== serviceId
                );
            }
        });
    };
    useEffect(() => {
        getApplicationSelectedServices(single.application.id, parkingId, selectedServices)
    }, [selectedServices])

    useEffect(() => {
        getSelectedServicesForFooterInfo(selectedServices)
    }, [getApplicationSelectedServices])

    useEffect(() => {
        const categoriesList = []
        single.parkingHasServices?.forEach(item => {
            const categoryItem = {
                catId: item.parking_service.service_category?.id,
                catName: item.parking_service.service_category?.name
            }
            const isItemUnique = !categoriesList.some(
                (category) => category.catId === categoryItem.catId
            );
            if (isItemUnique) {
                categoriesList.push(categoryItem);
                setCategories([...categoriesList]);
            }

        });

        setGroupedData(single.parkingHasServices);
    }, [single.parkingHasServices]);
    //set filter by default as first category
    useEffect(()=>{
        setFilterId(single.parkingHasServices[0].parking_service.service_category?.id)
    },[single.parkingHasServices])

    console.log(selectedServices)

    return (
        <>
            <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane fade show active mlr-20 table-data" id="v-pills-tab10" role="taborderspanel"
                     aria-labelledby="v-pills-tab10-tab">
                    <div className="filers__by-categories">
                        {categories && categories.map((category, index) => {
                            return (
                                <span
                                    key={category.catId}
                                    className={category.catId === filterId ? 'active' : ''}
                                    onClick={()=>{setFilterId(category.catId)}}
                                >
                                   {category.catName}
                               </span>
                            )
                        })}
                    </div>
                    <table className="price-list-table table-services">
                        <thead style={{borderBottom: '1px solid #ededed'}}>
                        <th>
                            <span>Наименование</span>
                        </th>
                        <th className={user.role === 'Partner' ? 'text-center' : ''}>
                            <span>
                                {user.role !== 'Partner' ? 'Цена для заказчика' : 'Цена' }
                            </span>
                        </th>
                        {user.role !== 'Partner' &&
                            <th>
                                <span>Наша цена</span>
                            </th>
                        }
                        <th></th>
                        </thead>

                        <tbody>
                        {groupedData && groupedData.length !== 0 && groupedData.map(item => (
                            <tr
                                key={item.id }
                                className={filterId === item.parking_service.service_category.id ? '' : 'd-none'}
                            >
                                <td>
                                    <div className="table-service-title">{item.parking_service.name}</div>
                                </td>
                                <td className={`text-center  ${user.role !== 'Partner' ? 'd-flex justify-content-between' : ''}`}>
                                    <span>
                                        {item.cost ? `${item.cost}` : 'не указано'}
                                    </span>
                                </td>
                                {user.role !== 'Partner' &&
                                    <td>
                                        <span>
                                            {item.cost_for_us ? `${item.cost_for_us}` : 'не указано'}
                                        </span>
                                    </td>
                                }
                                <td className="text-right d-flex justify-content-end">
                                    <label className="switch-radio-wrap d-flex flex-row-reverse">
                                        <input
                                            type="checkbox"
                                            onClick={(e) => {
                                                collectDataAboutSelectedServices(
                                                    e,
                                                    item.parking_id,
                                                    item.id,
                                                    item.cost,
                                                    item.cost_for_us,
                                                    item.parking_service.name
                                                )
                                            }}
                                        />
                                        <span className="switcher-radio ml-2 mr-0"></span>
                                        {selectedServices.find((s) => s.parking_has_service_id === item.id) ?
                                            <span className="condition text-green" style={{color: '#27AE60'}}>Добавлено </span> :
                                            <span className="condition">Добавить</span>
                                        }


                                    </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>

                    </table>
                </div>
            </div>
        </>
    );
}

export default AplicationServices;