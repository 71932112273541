import React from "react";
import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import { useDispatch, useSelector } from "react-redux";
import { selectArray, statusForReports } from "../../../../utils/select-array";
import { selectArrayParking } from "../../../../utils/select-array";
import Flatpickr from "react-flatpickr";
import { useState } from "react";
import { reportsByPartnerLink } from "../../../../store/reports/reportsByPartnerSlice/reportsByPartnerSlice";
import { useEffect } from "react";
import { Russian } from "flatpickr/dist/l10n/ru.js";

function ReportByPartnerForm({
  reportsByPartner,
  handleChangePartnerData,
  link,
  setStatusReports,
}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const partnersSelect = selectArray(reportsByPartner.partners, "partner_id[]");
  const parkingSelect = selectArrayParking(
    reportsByPartner.parking,
    "parking_id[]"
  );

  // Собираем данные для фильтрации
  const [selectedPatner, setSelectedPartner] = useState([]);
  const [selectedParking, setSelectedParking] = useState([]);
  const [dateStatus, setDateStatus] = useState();
  const [search, setSearch] = useState();
  const [dataPickerOption, setDataPickerOption] = useState('empty');

  const [costForAs, setCostForAs] = useState(false);

  useEffect(() => {
    dispatch(reportsByPartnerLink());
  }, []);

  const handleSelectChange = (selected, setSelectedOptions) => {
    setSelectedOptions(selected.map((option) => option.value));
  };

  const handleChange = (e) => {
    if (Array.isArray(e)) {
      let formattedDates = e;
      formattedDates = formattedDates
        .map((item) =>
          new Date(item)
            .toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .replace(/\./g, "-")
        )

        .join(" — ");
      setDateStatus({ ...dateStatus, dates: formattedDates });
    } else {
      const { name, value } = e.target ? e.target : e;
      setDateStatus({ ...dateStatus, [name]: value });
    }
  };

  const handleStatus = (e) => {
    const { value } = e;
    setDataPickerOption(value)
    setStatusReports(value);
  };
  //
  // Отправляем данные
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      partner_id: selectedPatner,
      parking_id: selectedParking,
      dates: dateStatus?.dates,
      status_id: dateStatus?.status_id,
      search: search,
    };
    if (costForAs){
      data.cost_for_us = 1
    }
    handleChangePartnerData(data);
  };
  //

  const handleCostForAsChange = () => {
    setCostForAs(prevState => !prevState)
  }

  const hadleClick = (e) => {
    e.preventDefault();
    dispatch(reportsByPartnerLink());
    window.location.replace(link.url);
  };
  return (
    <div className="container page-head-wrap">
      <div className="page-head">
        <div className="page-head__top d-flex align-items-center">
          <h1>{reportsByPartner.title}</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="filter">
        <div className="form-wrap-tablet d-flex align-items-center mb-3 ">
          {/* заказчик */}
          <label className="field-style  select-react__style">
            <span>заказчик</span>
            <Select
              isMulti
              options={partnersSelect}
              styles={selectStyle}
              onChange={(selected) =>
                handleSelectChange(selected, setSelectedPartner)
              }
              id="status-selection"
              name="partner_id[]"
            />
          </label>
          {/*  */}

          {/* Стоянка */}
          <label className="field-style select-react__style">
            <span>Стоянка</span>
            <Select
              isMulti
              options={parkingSelect}
              styles={selectStyle}
              onChange={(selected) =>
                handleSelectChange(selected, setSelectedParking)
              }
              name="parking_id[]"
            />
          </label>
          {/*  */}

          {/* Статус */}
          <label className="field-style  select-react__style">
            <span>Статус</span>
            <Select
              options={statusForReports}
              styles={selectStyle}
              onChange={(e) => {
                handleChange(e);
                handleStatus(e);
              }}
              id="status-selection"
              name="status_id"
            />
          </label>
          {/*  */}

          {/* Поиск по машине */}
          <label className="field-style  select-react__style report-input ">
            <span>Поиск по машине</span>

            <input
              type="text"
              name="search"
              className="w-100"
              onChange={(e) => setSearch(e.target.value)}
            />
          </label>
          {/*  */}

          {/* Дата отчета */}
          <label className="field-style select-react__style report-input ">
            <span>Даты отчёта</span>
            <Flatpickr
              options={{
                mode: `${dataPickerOption === 'instorage' ? 'single' : "range"}`,
                dateFormat: "d-m-Y",
                locale: {
                  ...Russian,
                  months: {
                    ...Russian.months,
                    longhand: [
                      "Января",
                      "Февраля",
                      "Марта",
                      "Апреля",
                      "Мая",
                      "Июня",
                      "Июля",
                      "Августа",
                      "Сентября",
                      "Октября",
                      "Ноября",
                      "Декабря",
                    ],
                  },
                  rangeSeparator: "—",
                },
              }}
              onChange={handleChange}
              name="dates"
              className="w-100"
            />
          </label>
          {/*  */}

          <label className="chosen-post">
            <input
              type="checkbox"
              name="favorite"
              className="checkbox-chosen"
            />
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.5C12.3788 2.5 12.7251 2.714 12.8945 3.05279L15.4734 8.2106L21.144 9.03541C21.5206 9.0902 21.8335 9.35402 21.9511 9.71599C22.0687 10.078 21.9706 10.4753 21.6981 10.741L17.571 14.7649L18.4994 20.4385C18.5608 20.8135 18.4043 21.1908 18.0957 21.4124C17.787 21.6339 17.3794 21.6614 17.0438 21.4834L12 18.8071L6.95624 21.4834C6.62062 21.6614 6.21306 21.6339 5.9044 21.4124C5.59573 21.1908 5.4393 20.8135 5.50065 20.4385L6.42906 14.7649L2.30193 10.741C2.02942 10.4753 1.93136 10.078 2.04897 9.71599C2.16658 9.35402 2.47946 9.0902 2.85609 9.03541L8.5267 8.2106L11.1056 3.05279C11.275 2.714 11.6213 2.5 12 2.5ZM12 5.73607L10.082 9.57221C9.93561 9.86491 9.65531 10.0675 9.33147 10.1146L5.14842 10.723L8.19813 13.6965C8.43182 13.9243 8.53961 14.2519 8.4869 14.574L7.80004 18.7715L11.5313 16.7917C11.8244 16.6361 12.1756 16.6361 12.4687 16.7917L16.2 18.7715L15.5132 14.574C15.4604 14.2519 15.5682 13.9243 15.8019 13.6965L18.8516 10.723L14.6686 10.1146C14.3448 10.0675 14.0645 9.86491 13.9181 9.57221L12 5.73607Z"
                fill="#536E9B"
              />
              <path
                d="M9 9L3.5 10L7.5 14.5L7 20.5L12 18L17.5 20.5L16.5 14L21 10L15 9L12 4L9 9Z"
                fill="transparent"
              />
            </svg>
          </label>
          {user.role === 'Admin' || user.role === 'SuperAdmin' || user.role === 'Manager' ?
              <label className="switch-radio-wrap">
                <input
                    className="checkbox-unknown clicense"
                    type="checkbox"
                    data-for="license_plate"
                    name="costForAs"
                    value="0"
                    onChange={handleCostForAsChange}
                />
                <span
                    className="switcher-radio"
                >

              </span>
                <span style={{fontSize:'11px', lineHeight:'10px', minWidth:'50px'}}>Цена для нас</span>
              </label> : ''
          }
        </div>
        <button type="submit" className="btn btn-primary ml-auto">
          Показать
        </button>
        <div className="btn btn-dowenload" onClick={hadleClick}></div>
        <label className="field-style pl-5">
          Всего: {reportsByPartner.data.total}
        </label>
      </form>
    </div>
  );
}

export default ReportByPartnerForm;
