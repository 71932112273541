import React, {useEffect, useState} from 'react';
import Preloader from "../../../../../preloader/preloader";
import ApplicationCurrentService from "./application-current-service";
import {applicationServiceApprovePartner} from "../../../../../../store/applications/application-service-order-partner-approve/applicationServiceOrderPartnerApproveSlice";
import {
    applicationServicesGetById,
    currentApplicationServiceById
} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import swal from "sweetalert";
import {useDispatch} from "react-redux";
import {applicationServiceApproveManager} from "../../../../../../store/applications/application-service-order-manger-approve/applicationServiceOrderMangerApproveSlice";

function AplicationServicesItem(
    {
        currentServices,
        role,
        approvedPrice,
        setApprovedPrice,
        toggleCompleteApplicationService,
        orderServices,
        setOrderServices,
        handleDeleteService,
        services,
        setKey,
        currentApplicationId,
        orderID
    }) {

    const dispatch = useDispatch()
    const [checkedItems, setCheckedItems]= useState({})
    const [pricesEditable, setPricesEditable]= useState(false)
    const [prices, setPrices]= useState({})
    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().slice(2, 4);

        return `${day}.${month}.${year}`;
    };
    const partnerApproveHandle = (id, isChecked) => {
        if (isChecked) {
            // Если чекбокс выбран, добавляем объект в массив
            const updatedServices = [...orderServices.orderServices, { id, status_id: 3 }];
            setOrderServices({ orderServices: updatedServices });
        } else {
            // Если чекбокс отменен, удаляем объект из массива
            const updatedServices = orderServices.orderServices.filter(service => service.id !== id);
            setOrderServices({ orderServices: updatedServices });
        }
    }
    const handleDefaultCheckedState = (serviceId, serviceCompleted) => {
        return serviceCompleted === 1;
    };
    const checkStatus = (serviceId, serviceCompleted) => {
        toggleCompleteApplicationService(serviceId);
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [serviceId]: !prevCheckedItems[serviceId],
        }));
    };
    // Set default checked state when component mounts
    useEffect(() => {
        const defaultCheckedState = {};
        currentServices?.orderServices?.forEach((service) => {
            defaultCheckedState[service.id] = handleDefaultCheckedState(service.id, service.completed);
        });
        setCheckedItems(defaultCheckedState);
    }, [currentServices?.orderServices]);
    useEffect(()=>{
        if (services.currentService && services.currentService.orderServices) {
            const extractedPrices = services.currentService.orderServices.map(service => ({
                id: service.id,
                price_for_us: service.price_for_us,
                approved_price: service.approved_price,
            }));
            setPrices(extractedPrices);
        }
    },[services.currentService.orderServices])
    const handleInputChange = (e, id) =>{
        const {value, name} = e.target
        setPrices(prevPrices =>
            prevPrices.map(price =>
                price.id === id ? { ...price, [name]: value } : price
            )
        );
    }
    const saveNewPrices = () =>{
        const currentApplicationId = services?.currentService?.order?.application_id
        const currentApplicationOrderId = services?.currentService?.order?.id
        const data = {
            orderServices: prices.map(price => ({
                id: price.id,
                price_for_us: price.price_for_us,
                price: price.approved_price,
            })),
            start:true
        }

            dispatch(applicationServiceApproveManager({currentApplicationId, currentApplicationOrderId, data}))
            .then((response) => {

                if (response.meta.requestStatus === "fulfilled") {
                    const orderID = currentApplicationOrderId;
                    dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
                    swal("цены успешно изменены", {
                        icon: "success",
                    }).then(() => {
                        dispatch(applicationServicesGetById({currentApplicationId}))
                        setPricesEditable(prevState => !prevState)
                    });
                } else {
                    swal("Ошибка при изменении цен", {
                        icon: "error",
                    }).then(() => {
                    });
                }
            })
    }
    switch (role){
        case 'Partner' :
        case 'Operator' :
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <table className="price-list-table table-orders">
                            <thead
                                style={{ width: '100%'}}
                            >
                            <tr>
                                <td
                                    colSpan={4}
                                    style={{ textAlign: 'center'}}
                                >
                                    Заказ № {services?.currentService.order?.id}  от {formatDate(services?.currentService.order?.created_at)}
                                </td>

                            </tr>
                            </thead>
                            <tbody>
                            {currentServices?.orderServices?.map((service, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="table-service-title">{service.parking_has_service.parking_service.name}</div>
                                        </td>

                                        <td className={`text-center price_service ${service.approved_price === 0 ? 'text-right' : ''}`}>
                                            {service.approved_price === 0 ?
                                                <>
                                                    <span className={'tip-service'}>?</span>
                                                    <span className={'tip-content'}>
                                                        {service.parking_has_service.comment}
                                                    </span>
                                                </>
                                                : ''
                                            }
                                            {service.approved_price}
                                        </td>
                                        <td className="text-right">
                                            {service.status === 'На согласовании' ?
                                                <span >
                                                    {currentServices?.order?.status_code === 'toManager' ?
                                                    'Ожидает согласования'
                                                    : 'Согласовать'
                                                    }
                                                </span>
                                                :
                                                <span
                                                style={{color:'green'}}
                                                > {service.status} </span>
                                            }

                                        </td>
                                        {service.status === 'На согласовании' && currentServices?.order?.status_code !== 'toManager' ?
                                        <td className="text-right">
                                            <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e)=>{partnerApproveHandle(service.id, e.target.checked)}}
                                                />
                                                <span className="switcher-radio ml-2 mr-0"></span>
                                            </label>
                                        </td> : <td></td> }

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                );
                break;
        case 'Manager' : case  'SuperAdmin' : case 'Admin' :
            if (currentServices?.order?.status_code === 'toManager') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" >
                        {/*<div*/}
                        {/*    className={'back_to_services d-flex justify-content-start align-items-center cursor-pointer'}*/}
                        {/*    onClick={() => {*/}
                        {/*        setKey('serviceList')*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#b5b5b5d1" d="m11 18l-6-6l6-6l1.4 1.4L7.825 12l4.575 4.6zm6.6 0l-6-6l6-6L19 7.4L14.425 12L19 16.6z"/></svg>*/}
                        {/*    <span> мои заказы</span>*/}
                        {/*</div>*/}
                        <table className="price-list-table table-orders">
                            <thead
                                style={{ width: '100%', borderBottom:'1px solid #e7e7e7'}}
                            >
                            {/*<tr>*/}
                                {/*<td colSpan="4">*/}
                                {/*    Заказ № {services?.currentService.order?.id } { }*/}
                                {/*     от {formatDate(services?.currentService.order?.created_at)}*/}
                                {/*</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <th style={{paddingLeft: 0}}></th>
                                <th>Наименование</th>
                                <th>Цена для заказчика</th>
                                <th>Наша цена</th>
                                <th></th>
                            </tr>

                            </thead>
                            <tbody className={'modal-services__list'}>
                            {currentServices?.orderServices ? currentServices?.orderServices.map((service, index) => {
                                return (
                                    <ApplicationCurrentService
                                        service={service}
                                        setApprovedPrice={setApprovedPrice}
                                        handleDeleteService={handleDeleteService}
                                        currentApplicationId={currentApplicationId}
                                        orderID={orderID}
                                />
                                )
                            }) : <Preloader/>}
                            </tbody>
                        </table>
                    </div>
                );
            }
            if (currentServices?.order?.status_code === 'toPartner') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div
                            className={'back_to_services d-flex justify-content-start align-items-center cursor-pointer'}
                            onClick={() => {
                                setKey('serviceList')
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#b5b5b5d1" d="m11 18l-6-6l6-6l1.4 1.4L7.825 12l4.575 4.6zm6.6 0l-6-6l6-6L19 7.4L14.425 12L19 16.6z"/></svg>
                            <span> мои заказы</span>
                        </div>
                        <table className="price-list-table table-orders">
                            <thead
                                style={{ width: '100%'}}
                            >
                                <tr>
                                     <td
                                         colSpan={4}
                                         style={{ textAlign: 'center'}}
                                     >
                                         Заказ № {services?.currentService.order?.id}  от {formatDate(services?.currentService.order?.created_at)}
                                     </td>

                                </tr>
                            </thead>
                            <tbody>
                            {currentServices?.orderServices?.map((service, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="table-service-title">{service.parking_has_service.parking_service.name}</div>
                                        </td>

                                        <td className="text-center">
                                               { service.approved_price}

                                        </td>
                                        <td className="text-right">
                                            <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                {/*<input*/}
                                                {/*    type="checkbox"*/}
                                                {/*/>*/}
                                                {/*<span className="switcher-radio ml-2 mr-0"></span>*/}
                                                <span
                                                    style={service.status === 'На согласовании' ?
                                                        {color:'red' }: {color:'green'}}
                                                    className="condition">
                                                    {
                                                        service.status === 'На согласовании' ?
                                                            'Ожидаем согласования' : service.status
                                                    }
                                                </span>
                                            </label>
                                        </td>
                                        <td>
                                            {service.status === 'На согласовании' ?
                                                <label>
                                                <span>
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <g opacity="0.6">
                                                            <path
                                                                d="M4.08335 2.33329C4.08335 1.68896 4.60569 1.16663 5.25002 1.16663H8.75002C9.39435 1.16663 9.91669 1.68896 9.91669 2.33329V3.49996H11.0773C11.0809 3.49993 11.0846 3.49993 11.0882 3.49996H12.25C12.5722 3.49996 12.8334 3.76113 12.8334 4.08329C12.8334 4.40546 12.5722 4.66663 12.25 4.66663H11.6265L11.1206 11.7497C11.077 12.3603 10.5689 12.8333 9.95687 12.8333H4.04317C3.43109 12.8333 2.92308 12.3603 2.87947 11.7497L2.37353 4.66663H1.75002C1.42785 4.66663 1.16669 4.40546 1.16669 4.08329C1.16669 3.76113 1.42785 3.49996 1.75002 3.49996H2.91187C2.91549 3.49993 2.9191 3.49993 2.92271 3.49996H4.08335V2.33329ZM5.25002 3.49996H8.75002V2.33329H5.25002V3.49996ZM3.54317 4.66663L4.04317 11.6666H9.95687L10.4569 4.66663H3.54317ZM5.83335 5.83329C6.15552 5.83329 6.41669 6.09446 6.41669 6.41663V9.91663C6.41669 10.2388 6.15552 10.5 5.83335 10.5C5.51119 10.5 5.25002 10.2388 5.25002 9.91663V6.41663C5.25002 6.09446 5.51119 5.83329 5.83335 5.83329ZM8.16669 5.83329C8.48885 5.83329 8.75002 6.09446 8.75002 6.41663V9.91663C8.75002 10.2388 8.48885 10.5 8.16669 10.5C7.84452 10.5 7.58335 10.2388 7.58335 9.91663V6.41663C7.58335 6.09446 7.84452 5.83329 8.16669 5.83329Z"
                                                                fill="#EB5757"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                </label>
                                                : ''}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                );
            }
            if (currentServices?.order?.status_code === 'approved' ||
                currentServices?.order?.status_code === 'in-progress' ||
                currentServices?.order?.status_code === 'partly-completed') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div
                            className={'back_to_services d-flex justify-content-start align-items-center cursor-pointer'}
                            onClick={() => {
                                setKey('serviceList')
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#b5b5b5d1" d="m11 18l-6-6l6-6l1.4 1.4L7.825 12l4.575 4.6zm6.6 0l-6-6l6-6L19 7.4L14.425 12L19 16.6z"/></svg>
                            <span> мои заказы</span>
                        </div>

                        <table className="price-list-table table-orders">
                            <thead
                                style={{ width: '100%'}}
                            >
                            <tr>
                                <td
                                    colSpan={4}
                                    style={{ textAlign: 'center'}}
                                >
                                    Заказ № {services?.currentService.order?.id}  от {formatDate(services?.currentService.order?.created_at)}
                                </td>

                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className={'d-flex justify-content-end'}>
                                    {!pricesEditable ?
                                    <span
                                        className={'edit-prise_for_us cursor-pointer'}
                                        onClick={()=>{setPricesEditable(prevState => !prevState )}}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5774 1.91058C13.9028 1.58514 14.4305 1.58514 14.7559 1.91058L18.0893 5.24391C18.4147
                                                5.56935 18.4147 6.09699 18.0893 6.42243L7.25592 17.2558C7.09964 17.412 6.88768 17.4998 6.66667
                                                17.4998H3.33333C2.8731 17.4998 2.5 17.1267 2.5 16.6665V13.3332C2.5 13.1122 2.5878 12.9002
                                                2.74408 12.7439L11.0772 4.41075L13.5774 1.91058ZM11.6667 6.17835L4.16667
                                                13.6783V15.8332H6.32149L13.8215 8.33317L11.6667 6.17835ZM15
                                                7.15466L16.3215 5.83317L14.1667 3.67835L12.8452 4.99984L15 7.15466Z" fill="#536E9B">
                                            </path>
                                        </svg>
                                    </span> :
                                        <span
                                            className={'save-price_for_us cursor-pointer'}
                                            onClick={()=>{saveNewPrices()}}
                                        >
                                         <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                              xmlns="http://www.w3.org/2000/svg" className="ml-2">
                                            <path
                                                d="M0.25 1.75C0.25 0.921573 0.921573 0.25 1.75 0.25H4.75H9.25H10.4393C10.8372 0.25
                                                 11.2187 0.408035 11.5 0.68934L13.5303 2.71967C13.671 2.86032 13.75 3.05109 13.75
                                                  3.25V12.25C13.75 13.0784 13.0784 13.75 12.25 13.75H9.25H4.75H1.75C0.921573
                                                  13.75 0.25 13.0784 0.25 12.25V1.75ZM4.75 12.25H9.25V7.75H4.75V12.25ZM10.75
                                                  12.25H12.25V3.56066L10.75 2.06066V3.25C10.75 4.07843 10.0784 4.75 9.25
                                                   4.75H4.75C3.92157 4.75 3.25 4.07843 3.25 3.25V1.75H1.75V12.25H3.25V7.75C3.25
                                                   6.92157 3.92157 6.25 4.75 6.25H9.25C10.0784 6.25 10.75 6.92157 10.75 7.75V12.25ZM4.75 1.75V3.25H9.25V1.75H4.75Z"
                                                fill="#536E9B"></path>
                                        </svg>
                                    </span>
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td></td>
                                <td>Заказчику</td>
                                <td>Наше</td>
                                <td></td>
                            </tr>
                            {currentServices?.orderServices?.map((service, index) => {
                                const isChecked = checkedItems[service.id];
                                return (
                                    <>

                                        <tr>
                                            <td>
                                                <div className="table-service-title">{service.parking_has_service.parking_service.name}</div>
                                            </td>

                                            <td className="text-center">
                                                {pricesEditable ?
                                                    <input
                                                        type="text"
                                                        name={'approved_price'}
                                                        defaultValue={service.approved_price}
                                                        style={{maxWidth:'100px'}}
                                                        onChange={(e) => { handleInputChange(e, service.id) }}
                                                    /> :
                                                    service.approved_price
                                                }
                                            </td>
                                            <td className="text-center">
                                                {pricesEditable ?
                                                    <input
                                                        type="text"
                                                        name={'price_for_us'}
                                                        defaultValue={service.price_for_us}
                                                        style={{maxWidth:'100px'}}
                                                        onChange={(e) => { handleInputChange(e, service.id) }}
                                                    /> :
                                                        service.price_for_us
                                                }
                                            </td>
                                            <td className="text-right ">
                                                {!pricesEditable && service.price_for_us !== 0 && service.approved_price !== 0  ?
                                                    <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={() => {
                                                                checkStatus(service.id, service.completed)
                                                            }}
                                                        />
                                                        <span className="switcher-radio ml-2 mr-0"></span>
                                                        <span className="condition">
                                                                {
                                                                    service.status === 'Согласована' ?
                                                                        'Не выполнено' : service.status
                                                                }
                                                            </span>
                                                    </label> :
                                                    <div>
                                                        {/*<span> нельзя цену для нас оставлять 0 </span>*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#CC421F" d="m12 13.4l2.9 2.9q.275.275.7.275t.7-.275t.275-.7t-.275-.7L13.4 12l2.9-2.9q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275L12 10.6L9.1 7.7q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7l2.9 2.9l-2.9 2.9q-.275.275-.275.7t.275.7t.7.275t.7-.275zm0 8.6q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"/></svg>

                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                );
            }
            if (currentServices?.order?.status_code === 'cancelled' || currentServices?.order?.status_code === 'completed'){
                return (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div
                            className={'back_to_services d-flex justify-content-start align-items-center cursor-pointer'}
                            onClick={() => {
                                setKey('serviceList')
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#b5b5b5d1" d="m11 18l-6-6l6-6l1.4 1.4L7.825 12l4.575 4.6zm6.6 0l-6-6l6-6L19 7.4L14.425 12L19 16.6z"/></svg>
                            <span> мои заказы</span>
                        </div>
                        <table className="price-list-table table-orders">
                            <thead
                                style={{ width: '100%'}}
                            >
                            <tr>
                                <td
                                    colSpan={4}
                                    style={{ textAlign: 'center'}}
                                >
                                    Заказ № {services?.currentService.order?.id}  от {formatDate(services?.currentService.order?.created_at)}
                                </td>

                            </tr>
                            </thead>
                            <tbody>
                            {currentServices?.orderServices?.map((service, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="table-service-title">{service.parking_has_service.parking_service.name}</div>
                                        </td>

                                        <td className="text-center">
                                            {service.approved_price}
                                        </td>
                                        <td className="text-right">
                                            {service.status === 'На согласовании' ?
                                                <span >
                                                    {currentServices?.order?.status_code === 'toManager' ?
                                                        'Ожидает согласования'
                                                        : 'Согласовать'
                                                    }
                                                </span>
                                                :
                                                <span
                                                    style={{color:'green'}}
                                                > {service.status} </span>
                                            }

                                        </td>
                                        {/*{service.status === 'На согласовании' && currentServices?.order?.status_code === 'cancelled' ?*/}
                                        {/*    <td className="text-right">*/}
                                        {/*        <label className="switch-radio-wrap d-flex flex-row-reverse">*/}
                                        {/*            <input*/}
                                        {/*                type="checkbox"*/}
                                        {/*                onChange={(e)=>{partnerApproveHandle(service.id, e.target.checked)}}*/}
                                        {/*            />*/}
                                        {/*            <span className="switcher-radio ml-2 mr-0"></span>*/}
                                        {/*        </label>*/}
                                        {/*    </td> : <td></td> }*/}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                )
            }
        default :
            return currentServices && (
                <Preloader />
            );
    }
}

export default AplicationServicesItem;