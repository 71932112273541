import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  userCreate,
  userCreatePost,
} from "../../../store/users/user-create-slice/userCreateSlice";
import { selectArrayRoles } from "../../../utils/select-array";
import selectStyle from "../../../utils/select-styles";
import InputMask from "react-input-mask";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function UserCreatePage() {
  const { usersCreate } = useSelector((state) => state.usersCreate);
  const [password, setPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState(true);
  const navigate = useNavigate();
  const [userCreateForm, setUserCreateForm] = useState({
    name: "",
    status: 0,
    email: "",
    phone: "",
    role: "",
    password: "",
    password_confirmation: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userCreate());
  }, [dispatch]);
  const handlePasswordVisible = () => {
    setPassword(!password);
  };

  const handleConfirmPasswordVisible = () => {
    setConfirmPassword(!confirmPassword);
  };

  // Функция для отправки формы
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    setUserCreateForm({ ...userCreateForm, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userCreatePost(userCreateForm)).then((response) => {
      if (response.payload.success === "Сохранено.") {
        swal("Пользователь успешно создан!", {
          icon: "success",
        }).then(() => {
          navigate("/users");
        });
      } else {
        let errorMessage = "Ошибка при создании пользователя!";
        if (response.payload && response.payload.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  //
  const roles = selectArrayRoles(
    usersCreate.length !== 0 ? usersCreate.roles : []
  );

  return (
    <>
      {usersCreate && usersCreate?.length !== 0 && (
        <form onSubmit={handleSubmit}>
          <div className="container page-head-wrap">
            <div className="page-head">
              <div className="page-head__top d-flex align-items-center">
                <h1>{usersCreate.title} </h1>
                <div className="ml-auto d-flex">
                  <button type="submit" className="btn btn-white">
                    Создать
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="inner-page">
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="inner-page__item">
                    <div className="inner-item-title">О пользователе</div>
                    <div className="row">
                      <div className="col-6">
                        <label className="field-style">
                          <span>Фамилия, имя</span>
                          <input
                            id="name"
                            type="text"
                            value={userCreateForm.name}
                            name="name"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />

                          <span className="invalid-feedback" role="alert">
                            <strong></strong>
                          </span>
                        </label>
                      </div>
                      <div className="col-6">
                        <label className="switch-radio-wrap mt-11px">
                          <input
                            type="checkbox"
                            name="status"
                            value={userCreateForm.status}
                            onChange={handleChange}
                          />
                          <span className="switcher-radio"></span>
                          <span>Активен</span>
                        </label>
                      </div>
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>E-Mail</span>
                          <InputMask
                            maskChar="_"
                            value={userCreateForm.email}
                            id="email"
                            name="email"
                            required
                            onChange={handleChange}
                            placeholder="example@example.com"
                          />
                        </label>
                      </div>
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>Тел</span>
                          <InputMask
                            // mask="+7 (999) 999-99-99"
                            maskChar="_"
                            value={userCreateForm.phone}
                            id="phone"
                            name="phone"
                            required
                            onChange={handleChange}
                            placeholder="+7 (___) ___-__-__"
                          />
                        </label>
                      </div>

                      <div className="col-6 mt-3">
                        <label className="field-style select-react__style">
                          <span>Роль</span>
                          <Select
                            defaultValue={{
                              value: "0",
                              label: "Выберите роль.",
                              name: "role",
                            }}
                            options={roles}
                            styles={selectStyle}
                            onChange={handleChange}
                            id="status-selection"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="inner-page__item">
                    <div className="inner-item-title">Доступы</div>
                    <div className="row">
                      <div className="col-6">
                        <label className="field-style">
                          <span>Пароль*</span>
                          <span className="password">
                            <input
                              type={password ? "password" : "text"}
                              name="password"
                              placeholder="Не указан"
                              value={userCreateForm.password}
                              autoComplete="off"
                              required
                              onChange={handleChange}
                            />
                            <div
                              className={`password-control  ${
                                password ? "" : "view"
                              }`}
                              onClick={handlePasswordVisible}
                            ></div>
                          </span>
                        </label>
                      </div>

                      <div className="col-6">
                        <label className="field-style">
                          <span>Подтверждение пароля*</span>
                          <span className="password">
                            <input
                              type={confirmPassword ? "password" : "text"}
                              value={userCreateForm.password_confirmation}
                              name="password_confirmation"
                              autoComplete="off"
                              required
                              placeholder="Не указан"
                              onChange={handleChange}
                            />
                            <div
                              className={`password-control  ${
                                confirmPassword ? "" : "view"
                              }`}
                              onClick={handleConfirmPasswordVisible}
                            ></div>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default UserCreatePage;
